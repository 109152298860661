import { Editor, Range } from '@tiptap/react';
import { Editor as CoreEditor } from '@tiptap/core';
import { TBlockMenuItem } from '../Utils/TiptapTypes';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { selectCurrentStage, selectIsTemplateItem } from '../../ProcessFields/lib/processItemsSlice';
import { prepareMergeData } from '../Utils/MergeTemplateHelpers';
import { FieldSectionDTO } from '../../../../shared/Field/Field';
import { ECustomEventsNames } from '../../../../shared/events/CustomEvents';
import { useItemFileUpload } from '../../FilesUpload/FilesUploadProvider/FilesUploadProvider';
import { selectProcess } from '../../../routes-old/process/state/processSlice';
import useCurrentItemUpdate from '../../../hooks/useProcessItemUpdate';

const useTiptapBlockMenu = ({
  editor,
  position,
  range,
}: {
  editor: Editor | CoreEditor | null,
  position?: number;
  range?: Range,
}) => {
  const dispatch = useAppDispatch();
  const currentStage = useAppSelector(selectCurrentStage);
  const isTemplate = useAppSelector(selectIsTemplateItem);
  const process = useAppSelector(selectProcess);
  const { onUpload } = useItemFileUpload();
  const { updateCurrentItemFile } = useCurrentItemUpdate();

  const insertParagraph = (pos?: number) => {
    if (typeof position === 'undefined' || !editor) {
      return;
    }
    editor
      .chain()
      .insertContentAt(
        pos ?? position,
        {
          type: 'paragraph',
        },
      )
      .focus()
      .run();
    window.dispatchEvent(new CustomEvent(
      ECustomEventsNames.TIPTAP_OPEN_BLOCK_MENU,
      {
        detail: {
          newBlockId: editor?.state.selection?.$anchor?.parent.attrs.blockId,
          isTable: editor?.$pos(pos ?? position)?.node?.type?.name === 'tableHeader',
        },
      },
    ));
  };

  const replaceParagraph = (item: TBlockMenuItem, p?: number, files?: File[]) => {
    const at = position ?? p ?? range?.from;

    if (!editor || (typeof at === 'undefined')) {
      return;
    }
    let doc: undefined | string;
    if (files?.[0]) {
      doc = window.crypto.randomUUID();
    }

    const nodePos = editor?.$pos(editor?.state.selection.from) ?? { node: undefined };
    const { node: targetNode } = nodePos;
    if (
      targetNode?.textContent
      && (targetNode.textContent.trim()?.length > 1)
    ) {
      editor
        .chain()
        .focus(at)
        .deleteRange(editor.state.nodeCommands$!.range)
        .insertContentAt(
          editor.state.nodeCommands$!.range?.from,
          {
            type: item.type,
            attrs: { ...item.attrs, ...(doc ? { doc } : {}) },
            content: item.content,
          },
        )
        .run();
    } else {
      editor
        .chain()
        .focus(at)
        .deleteRange({ from: at, to: editor.state.selection.to })
        .insertContentAt(
          at,
          {
            type: item.type,
            attrs: { ...item.attrs, ...(doc ? { doc } : {}) },
            content: item.content,
          },
        )
        .run();
    }

    if (files?.[0]) {
      onUpload(files[0], { docId: doc });
    }
  };

  const insertAfter = (item: TBlockMenuItem): void => {
    if (typeof position === 'undefined' || !editor) {
      return;
    }
    switch (item.type) {
      case 'table':
        editor
          .chain()
          .insertContentAt(
            position,
            {
              type: 'paragraph',
            },
          )
          .focus()
          .run();
        editor.commands.insertTable({ rows: 1, cols: item.columns });
        return;
      default:
        editor
          .chain()
          .insertContentAt(
            position,
            {
              type: item.type,
              attrs: { ...item.attrs },
              content: item.content,
            },
          )
          .focus(position)
          .run();
    }
  };

  // const replaceWith = (item: TBlockMenuItem, files?: File[]): void => {
  //   if (!range || !editor) {
  //     return;
  //   }
  //   const nodePos = editor.$pos(range.from);
  //   const { node: targetNode } = nodePos;

  //   let doc: undefined | string;
  //   if (files?.[0]) {
  //     doc = window.crypto.randomUUID();
  //   }

  //   if (
  //     targetNode.textContent.trim()?.length > 1
  //     && ['heading', 'paragraph'].includes(targetNode.type.name)
  //   ) {
  //     editor
  //       .chain()
  //       .focus()
  //       .deleteNode(targetNode.type.name)
  //       .insertContentAt(
  //         nodePos.pos - 3,
  //         {
  //           type: item.type,
  //           attrs: { ...item.attrs, ...(doc ? { doc } : {}) },
  //           content: item.content,
  //         },
  //       )
  //       .run();
  //   } else {
  //     editor
  //       .chain()
  //       .focus()
  //       .insertContentAt(
  //         nodePos.to - 1,
  //         {
  //           type: item.type,
  //           attrs: { ...item.attrs, ...(doc ? { doc } : {}) },
  //           content: item.content,
  //         },
  //       )
  //       .run();
  //   }
  //   if (files?.[0]) {
  //     onUpload(files[0], { docId: doc });
  //   }
  //   editor?.commands.setTextSelection(range);
  //   editor?.commands.deleteSelection();
  //   editor?.commands.focus(range.from + 2);
  // };

  const useTemplate = (item: FieldSectionDTO) => {
    const mergeData = prepareMergeData(item, currentStage, { isTemplate, buyerName: process?.client, clientName: process?.clientName });
    if (mergeData) {
      if (range && !position) {
        editor
          ?.chain()
          .focus()
          .deleteRange(range)
          .insertContentAt(range.from, mergeData.items.content)
          .run();
        if (mergeData.files) {
          updateCurrentItemFile(mergeData.files);
        }
        return;
      }
      if (typeof position === 'number') {
        editor?.chain().focus().insertContentAt(position, mergeData.items.content).run();
        if (mergeData.files) {
          updateCurrentItemFile(mergeData.files);
        }
      }
    }
  };

  return {
    insertAfter,
    // replaceWith,
    useTemplate,
    insertParagraph,
    replaceParagraph,
  };
};

export default useTiptapBlockMenu;
