import { isMobile } from 'react-device-detect';
import { TabContext } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectSettingsTab } from '../../features/Layout/lib/fieldsLayoutSlice';
import { handleScroll } from '../process/helpers';
import { MenuLogo22 } from '../../icons/MenuLogo';
import {
  SettingsCardsHeader,
  SettingsCardsHeadingWrapper,
  SettingsCardsTabPanel,
  SettingsNewLayout,
  SettingsNewTabsWrapper,
  SettingsScrollableContainer,
} from './SettingsTabs/Styles/GeneralTabStyles';
import { settingsMenuItems } from '../../core/menu/settingsMenu';
import { AppSidebar, SettingsTabHeader } from '../../features/Stages/Styles/SidebarStyles';
import SettingsMenu from '../../features/SettingsMenu/SettingsMenu';
import useUserOrganizationPermissions from '../../hooks/useOrganizationPermissions';
import { EUserOrganizationPermissions } from '../../../shared/permissions';

const SettingsNew = (): JSX.Element | null => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.app.user);
  const settingsTab = useAppSelector(selectSettingsTab);
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const teamManager: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_TEAM_MANAGE);
  if (!user) return null;

  const settingsTabs = useMemo(() => {
    switch (teamManager) {
      case true:
        return (
          settingsMenuItems.map((item) => (
            <SettingsCardsTabPanel
              key={item.settingsTab}
              className={classNames({ 'settings-cards-mobile': isMobile })}
              value={item.settingsTab}
            >
              {item.teammanagerComponent}
            </SettingsCardsTabPanel>
          ))
        );
      default:
        return (
          settingsMenuItems.map((item) => (
            <SettingsCardsTabPanel
              key={item.settingsTab}
              className={classNames({ 'settings-cards-mobile': isMobile })}
              value={item.settingsTab}
            >
              {item.teammateComponent}
            </SettingsCardsTabPanel>
          ))
        );
    }
  }, []);

  return (
    <SettingsNewLayout
      className={classNames({ 'mobile-settings-layout': isMobile })}
    >
      {!isMobile && (
        <AppSidebar
          className={classNames('sidebar', 'sidebar-settings', { 'sidebar-mobile': isMobile })}
        >
          <SettingsTabHeader>
            {t('Settings_Settings')}
          </SettingsTabHeader>
          <SettingsMenu />
        </AppSidebar>
      )}
      <SettingsScrollableContainer
        onScroll={(e) => handleScroll(e.target as Element, 'settings-scrollable-container-active')}
        className={classNames('settings-scrollable-container', { 'mobile-settings-scrollable-container': isMobile })}
      >
        {isMobile && (
        <SettingsCardsHeadingWrapper
          className="mobile-heading-with-logo"
        >
          <SettingsCardsHeader>
            {t('Team_Settings')}
          </SettingsCardsHeader>
          <MenuLogo22 />
        </SettingsCardsHeadingWrapper>
        )}
        <SettingsNewTabsWrapper>
          <TabContext
            value={settingsTab}
          >
            {isMobile && (
            <SettingsMenu />
            )}
            {settingsTabs}
          </TabContext>
        </SettingsNewTabsWrapper>
      </SettingsScrollableContainer>
    </SettingsNewLayout>
  );
};

export default SettingsNew;
