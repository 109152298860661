import { Box, Popover, Slide } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { TransitionProps } from '@mui/material/transitions';
import { RGBColor } from 'react-color';
import { useCurrentEditor } from '@tiptap/react';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';
import ButtonColor from '../../icons/broom20px.svg';
import WidthType from '../../icons/full-widh20.svg';
import AlignIcon from '../../icons/align-icon20px.svg';
import AlignCenter from '../../icons/align-center20px.svg';
import AlignLeft from '../../icons/align-left20px.svg';
import AlignRight from '../../icons/align-right20px.svg';
import OpenUrl from '../../icons/link20.svg';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { StageContentType } from '../../../shared/Stage';
import { useCurrentStage } from '../../hooks/useCurrentStage';
import { AppDropdown } from '../../shared/AppDropdown/AppDropdown';
import { stagesetColors } from '../../theme/stagesetPalette';
import { AppInput } from '../../shared/AppInput/AppInput';
import { AppButton } from '../../shared/AppButton/AppButton';
import BackgroundColorPicker from '../DealCustomizationMenu/BackgroundColorPicker';
import { rgbaObjToString } from '../DealCustomizationMenu/helpers/DealCustomizationMenuHelpers';
import { parseUrlStart } from '../../common/helpers';

const Transition = React.forwardRef((
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) => <Slide direction="down" ref={ref} {...props} />);

Transition.displayName = 'Transition';

const TiptapButtonNodeToolbarItems = (): JSX.Element => {
  const { editor } = useCurrentEditor();

  const {
    url, widthType, color, align, blockId,
  } = editor?.state.selection?.$anchor?.nodeAfter?.attrs ?? {};

  const isLayoutEditActive: boolean = useAppSelector(selectLayouEditingStatus);
  const mediaMoreBtnRef = useRef<HTMLDivElement>(null);

  const [widthMenuOpen, setWidthMenuOpen] = useState<boolean>(false);
  const [anchorWidthEl, setAnchorWidthEl] = useState<null | HTMLElement>(null);
  const [colorMenuOpen, setColorMenuOpen] = useState<boolean>(false);
  const [anchorColorEl, setAnchorColorEl] = useState<null | HTMLElement>(null);
  const [urlMenuOpen, setUrlMenuOpen] = useState<boolean>(false);
  const [anchorUrlEl, setAnchorUrlEl] = useState<null | HTMLElement>(null);
  const [localWidthType, setWidthType] = useState<string>(widthType || 'hug_content');
  const [localAlign, setAlign] = useState<string>(align || 'center');
  const [localUrl, setUrl] = useState(url);
  const [alignMenuOpen, setAlignMenuOpen] = useState<boolean>(false);
  const [anchorAlignEl, setAnchorAlignEl] = useState<null | HTMLElement>(null);
  const [localColor, setColor] = useState(color || 'primary');
  const [colorSelectorOpen, setColorSelectorOpen] = useState<boolean>(false);

  const [btnRef, setBtnRef] = useState<Element | null | undefined>(null);

  useEffect(() => {
    setBtnRef(document.getElementById(blockId)?.querySelector('#button-to-click'));
  }, [blockId]);

  useEffect(() => {
    setUrl(url);
  }, [url]);

  useEffect(() => {
    setColor(color || 'primary');
  }, [color]);

  useEffect(() => {
    setWidthType(widthType || 'hug_content');
  }, [widthType]);

  useEffect(() => {
    setAlign(align || 'center');
  }, [align]);

  const currentStage = useCurrentStage();

  useEffect(() => {
    if (mediaMoreBtnRef.current && isLayoutEditActive) {
      mediaMoreBtnRef.current.classList.add('MediaMoreMenu-visible');
    }
    return () => {
      if (mediaMoreBtnRef.current && isLayoutEditActive) {
        mediaMoreBtnRef.current.classList.remove('MediaMoreMenu-visible');
      }
    };
  }, [isLayoutEditActive]);

  const handleWidthTypeBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorWidthEl(event.currentTarget);
    setWidthMenuOpen(true);
    event.stopPropagation();
  };

  const handleColorTypeBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorColorEl(event.currentTarget);
    setColorMenuOpen(true);
    event.stopPropagation();
  };

  const handleUrlTypeBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUrl(editor?.state?.selection?.node?.attrs?.url ?? '');
    setAnchorUrlEl(event.currentTarget);
    setUrlMenuOpen(true);
    event.stopPropagation();
  };

  const handleAlignBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorAlignEl(event.currentTarget);
    setAlignMenuOpen(true);
    event.stopPropagation();
  };

  const isCustom = (c: string) => {
    if (c.startsWith('#')) {
      return true;
    }
    return false;
  };

  const alignmentButtonRenderer = () => {
    if (currentStage?.stageContentType !== StageContentType.WIDESCREENSTAGE) {
      return (
        <>
          <AppIconButton
            onClick={handleUrlTypeBtnClick}
            icon={<OpenUrl />}
            sx={{
              background: 'transparent',
              stroke: (theme) => `${theme.palette.grey[500]} !important`,
              '&:hover': {
                background: (theme) => `${theme.palette.grey[100]} !important`,
              },
              '&:active': {
                background: (theme) => `${theme.palette.grey[200]} !important`,
              },
            }}
          />
          <AppIconButton
            onClick={handleWidthTypeBtnClick}
            icon={<WidthType />}
            sx={{
              background: 'transparent',
              stroke: (theme) => `${theme.palette.grey[500]} !important`,
              '&:hover': {
                background: (theme) => `${theme.palette.grey[100]} !important`,
              },
              '&:active': {
                background: (theme) => `${theme.palette.grey[200]} !important`,
              },
            }}
          />
          <AppIconButton
            onClick={handleAlignBtnClick}
            icon={<AlignIcon />}
            sx={{
              background: 'transparent',
              stroke: (theme) => `${theme.palette.grey[500]} !important`,
              '&:hover': {
                background: (theme) => `${theme.palette.grey[100]} !important`,
              },
              '&:active': {
                background: (theme) => `${theme.palette.grey[200]} !important`,
              },
            }}
          />
          <AppIconButton
            onClick={handleColorTypeBtnClick}
            icon={<ButtonColor />}
            sx={{
              background: 'transparent',
              stroke: (theme) => `${theme.palette.grey[500]} !important`,
              '&:hover': {
                background: (theme) => `${theme.palette.grey[100]} !important`,
              },
              '&:active': {
                background: (theme) => `${theme.palette.grey[200]} !important`,
              },
            }}
          />
        </>
      );
    }
    return null;
  };

  const onColorChange = (newColor: string | RGBColor) => {
    const current = typeof newColor === 'string' ? newColor : rgbaObjToString(newColor);
    editor?.chain().focus().updateAttributes('buttonNode', { color: current }).run();
  };

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: currentStage?.stageContentType === StageContentType.WIDESCREENSTAGE ? '2px' : '10px',
        padding: '4px',
        height: '40px',
        borderRadius: '10px',
        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.60) -9.58%, #E2E8F0 100%)',
        backdropFilter: 'blur(14px)',
      }}
      >
        {alignmentButtonRenderer()}
      </Box>
      <AppDropdown
        items={[
          {
            key: 'full_width',
            label: 'Full width',
            selected: localWidthType === 'full_width',
            onClick: (e) => {
              e.stopPropagation();
              editor?.chain().focus().updateAttributes('buttonNode', { widthType: 'full_width' }).run();
              setAnchorWidthEl(null);
              setWidthMenuOpen(false);
            },
          },
          {
            key: 'hug_content',
            label: 'Hug content',
            selected: localWidthType === 'hug_content',
            onClick: (e) => {
              e.stopPropagation();
              editor?.chain().focus().updateAttributes('buttonNode', { widthType: 'hug_content' }).run();
              setAnchorWidthEl(null);
              setWidthMenuOpen(false);
            },
          },
        ]}
        open={widthMenuOpen}
        anchorEl={anchorWidthEl}
        onClose={() => { setAnchorWidthEl(null); setWidthMenuOpen(false); }}
      />
      <AppDropdown
        items={[
          {
            key: 'primary',
            label: 'Primary',
            selected: localColor === 'primary',
            onClick: (e) => {
              e.stopPropagation();
              editor?.chain().focus().updateAttributes('buttonNode', { color: 'primary' }).run();
              setAnchorColorEl(null);
              setColorMenuOpen(false);
            },
          },
          {
            key: 'secondary',
            label: 'Secondary',
            selected: localColor === 'secondary',
            onClick: (e) => {
              e.stopPropagation();
              editor?.chain().focus().updateAttributes('buttonNode', { color: 'secondary' }).run();
              setAnchorColorEl(null);
              setColorMenuOpen(false);
            },
          },
          {
            key: 'neutral',
            label: 'Neutral',
            selected: localColor === 'neutral',
            onClick: (e) => {
              e.stopPropagation();
              editor?.chain().focus().updateAttributes('buttonNode', { color: 'neutral' }).run();
              setAnchorColorEl(null);
              setColorMenuOpen(false);
            },
          },
          {
            key: 'custom',
            label: 'Custom',
            selected: isCustom(localColor),
            subLabel: isCustom(localColor) ? localColor : '',
            onClick: (e) => {
              e.stopPropagation();
              setColorMenuOpen(false);
              setColorSelectorOpen(true);
            },
          },
        ]}
        open={colorMenuOpen}
        anchorEl={anchorColorEl}
        onClose={() => { setAnchorColorEl(null); setColorMenuOpen(false); }}
      />
      <AppDropdown
        items={[
          {
            key: 'start',
            label: 'Align Left',
            selected: localAlign === 'start',
            startIcon: <AlignLeft />,
            onClick: (e) => {
              e.stopPropagation();
              editor?.chain().focus().updateAttributes('buttonNode', { align: 'start' }).run();
              setAnchorAlignEl(null);
              setAlignMenuOpen(false);
            },
          },
          {
            key: 'end',
            label: 'Align Right',
            selected: localAlign === 'end',
            startIcon: <AlignRight />,
            onClick: (e) => {
              e.stopPropagation();
              editor?.chain().focus().updateAttributes('buttonNode', { align: 'end' }).run();
              setAnchorAlignEl(null);
              setAlignMenuOpen(false);
            },
          },
          {
            key: 'center',
            label: 'Align Center',
            selected: localAlign === 'center',
            startIcon: <AlignCenter />,
            onClick: (e) => {
              e.stopPropagation();
              editor?.chain().focus().updateAttributes('buttonNode', { align: 'center' }).run();
              setAnchorAlignEl(null);
              setAlignMenuOpen(false);
            },
          },
        ]}
        open={alignMenuOpen}
        anchorEl={anchorAlignEl}
        onClose={() => { setAnchorAlignEl(null); setAlignMenuOpen(false); }}
      />
      <Popover
        open={colorSelectorOpen}
        anchorEl={btnRef}
        onClose={() => { setAnchorColorEl(null); setColorSelectorOpen(false); }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            marginTop: '4px',
            padding: '12px',
            borderRadius: '8px',
            border: '1px solid #5CA8FF',
            background: '#F2F3F7',
            // eslint-disable-next-line max-len
            boxShadow: '0px 3px 2px rgba(0, 113, 190, 0.04), 0px 1px 1px rgba(0, 113, 190, 0.07), 0px 0px 1px rgba(0, 113, 190, 0.08), 0px 0px 0px rgba(0, 113, 190, 0.08)',
          },
        }}
      >
        <BackgroundColorPicker
          initialColor={localColor}
          onColorConfirm={onColorChange}
        />
      </Popover>
      <Popover
        id={anchorUrlEl ? 'owner-card-popover' : undefined}
        open={urlMenuOpen}
        anchorEl={isMobile ? undefined : anchorUrlEl}
        anchorPosition={isMobile ? {
          top: 0,
          left: 0,
        } : undefined}
        onClose={() => { setAnchorUrlEl(null); setUrlMenuOpen(false); }}
        TransitionComponent={isMobile ? Transition : undefined}
        slotProps={{
          paper: {
            sx: {
              boxShadow: '0px 3px 2px rgba(0, 113, 190, 0.04), 0px 1px 1px rgba(0, 113, 190, 0.07), '
                + '0px 0px 1px rgba(0, 113, 190, 0.08), 0px 0px 0px rgba(0, 113, 190, 0.08)',
              borderRadius: '8px',
              border: '1px solid',
              borderColor: stagesetColors.newGrey[500],
              padding: '16px',
              width: '650px',
              ...(isMobile && {
                width: '100%',
                maxWidth: '100%',
                left: '0 !important',
                minHeight: '200px',
                top: 'auto !important',
                bottom: '0',
              }),
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          zIndex: 5,
          ...(isMobile && {
            '.MuiModal-backdrop': {
              backgroundColor: 'black',
              opacity: '0.2 !important',
            },
          }),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <AppInput
            label="Url"
            defaultValue={localUrl}
            type="text"
            value={localUrl}
            onChange={(event) => {
              setUrl(event.target.value);
            }}
            sx={{
              flex: 2,
            }}
          />
          <AppButton
            onClick={(e) => {
              e.stopPropagation();
              const eventURL = parseUrlStart(localUrl);
              editor?.chain().focus().updateAttributes('buttonNode', { url: eventURL }).run();
              setAnchorUrlEl(null);
              setUrlMenuOpen(false);
            }}
            variant="primary"
            size="l"
            sx={{
              marginLeft: '10px',
            }}
          >
            Save
          </AppButton>
        </Box>
      </Popover>
    </Box>
  );
};

export default TiptapButtonNodeToolbarItems;
