import { styled } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const CustomDomainGeneralWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '40px',
  '&.custom-domain-general-wrapper-mobile': {
    flexDirection: 'column',
  },
}));

export const SubdomainInputWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
}));

export const SubdomainLabel = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  fontSize: '16px',
  lineHeight: '22px',
  color: stagesetColors.newGrey[500],
}));

export const DomainSwitchWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&.question-icon': {
    '& svg': {
      '& path': {
        stroke: stagesetColors.newGrey[800],
      },
    },
  },
}));

export const CustomDomainFieldWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
}));

export const ButtonWrapper = styled(Box)(() => ({
  paddingTop: '16px',
}));

export const StatusTabletWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid',
  borderRadius: '16px',
  borderColor: stagesetColors.newGrey[200],
  backgroundColor: stagesetColors.newGrey[50],
  padding: '16px',
  gap: '16px',
}));

export const TabletTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const TabletContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  justifyContent: 'center',
}));

export const TabletTitle = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 500,
}));

export const TabletItem = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: '8px',
  padding: '3px 8px',
  borderRadius: '6px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: stagesetColors.red[100],
  '&.tablet-item-success': {
    backgroundColor: stagesetColors.green[100],
  },
}));

export const InstructionsPaper = styled(Box)(() => ({
  borderRadius: '20px',
  border: '1px solid',
  borderColor: stagesetColors.newGrey[200],
  backgroundColor: stagesetColors.white[100],
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  '&.instructions-paper-mobile': {
    padding: '16px',
  },
}));

export const InstructionsTable = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  '&.instructions-wrapper-mobile': {
    gap: '24px',
  },
}));

export const InfoTableTitle = styled(Typography)(() => ({
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: 600,
  color: stagesetColors.newGrey[800],
  paddingBottom: '24px',
}));

export const DomianTableListElement = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
}));

export const InfoTableSubtitle = styled(Typography)(() => ({
  // whiteSpace: 'nowrap',
  fontSize: '16px',
  lineHeight: '22px',
  color: stagesetColors.newGrey[900],
  '& span': {
    fontWeight: 500,
  },
}));

export const InfoTableSubtitleBold = styled(Box)(() => ({
  fontWeight: 500,
}));

export const DomainTable = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const InfoTableBullet = styled(Typography)(() => ({
  display: 'flex',
  width: '24px',
  height: '24px',
  minWidth: '24px',
  minHeight: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '22px',
  borderRadius: '6px',
  boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
    + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
    + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
    + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
  backgroundColor: stagesetColors.newGrey[50],
}));

export const InfoTableWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '16px',
  gap: '10px',
  padding: '0px 0px 0px 32px',
}));

export const TableRowWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirectionL: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '12px',
}));

export const CellHeader = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '16px',
  color: stagesetColors.newGrey[900],
  fontWeight: 500,
  marginBottom: '-4px',
}));

export const DomainCellTitle = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '16px',
  color: stagesetColors.newGrey[500],
}));

export const CellInfo = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  color: stagesetColors.newGrey[800],
}));

export const SwitchTitle = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 600,
  color: stagesetColors.newGrey[800],
}));

export const TitleSwitchGroup = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  cursor: 'pointer',
}));
